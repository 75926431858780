import React from "react";
import { Link } from 'react-router-dom';
import {
  NavItem,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const search = useLocation().search;
  var scl = new URLSearchParams(search).get('scl');

  var ug = new URLSearchParams(search).get('ug');
    var ul = new URLSearchParams(search).get('ul');
    var ft = new URLSearchParams(search).get('ft');


    var scl = "";
    if(ug=="us" && ul=="en") {
        scl = "en";
    }else if (ug=="us" && ul=="es") {
        scl = "es";
    } 
    else if (ug=="ca" && ul=="en") {
        scl = "en";
    } else if (ug=="ca" && ul=="fr") {
        scl = "fr";
    } else if (ug=="ch" && ul=="it") {
        scl = "it";
    }
    else if (ug=="it" && ul=="it") {
        scl = "it";
    }
    else if (ug=="ch" && ul=="de") {
        scl = "de";
    }
    else if (ug=="ch" && ul=="fr") {
        scl = "fr";
    }
    else if (ug=="us" && ul=="cass") {
        scl = "cass";
    }
    else if (ug=="my" && ul=="ms") {
      scl = "my";
    }
    else if (ug=="my" && ul=="en") {
      scl = "en";
    }
    else if (ug=="de" && ul=="de") {
      scl = "de";
    }
    else if (ug=="de" && ul=="en") {
      scl = "en";
    }
    else if (ug=="sa" && ul=="ar") {
      scl = "ar";
    }

    else if (ug=="mx" && ul=="mx") {
      scl = "mx";
    }
    else if (ug=="th" && ul=="th") {
      scl = "th";
    }
    else if (ug=="id" && ul=="id") {
      scl = "id";
    }
    else if (ug=="hk" && ul=="zh") {
      scl = "zh";
    }
    else if (ug=="vn" && ul=="vi") {
      scl = "vi";
    }
    
    else if (ug=="il" && ul=="he") {
      scl = "he";
    }
    else if (ug=="sg" && ul=="zh") {
      scl = "zh";
    }
  else if (ug=="sg" && ul=="zh-sg") {
      scl = "zh-sg";
    }

    else if (ug=="hk" && ul=="zh-hk") {
      scl = "zh-hk";
    }

    else {
        scl = "en";
    }

  return (
    <>
      <footer className="footer">
        <Container>
          <Row className="align-items-center justify-content-md-between">
            <Col md="6">
              <div className="copyright">
                &copy;2023 journey-finder, All Rights Reserved
              </div>
            </Col>
            <Col md="6">
              <Nav className="nav-footer justify-content-end">
                <NavItem>
                  <Link to="/" className="footer-link-rt">
                  {scl == "es" ? "Inicio" 
                  : scl == "ar" ? "الصفحة الرئيسية"
                  : scl == "he" ? "בית"
                  : scl == "it" ? "Home"
                    : scl == "fr" ? "Accueil"
                    : scl == "cass" ? "Inicio"
                    : scl == "de" ? "Startseite"
                    : scl == "my" ? "Laman Utama"
                    : scl == "mx" ? "Inicio"
                    : scl == "zh" ? "主页"
                    : scl == "th" ? "หน้าแรก"
                    : scl == "id" ? "Beranda"
                    : scl == "vi" ? "Trang chủ"
                    : scl == "zh-sg" ? "首頁"
                    : scl == "zh-hk" ? "首頁"
                                : 
                                "Home"}
                                </Link>
                </NavItem>
                <NavItem>
                  <Link to="/about" className="footer-link-rt">
                  {scl == "es" ? "Acerca de" 
                  : scl == "ar" ? "حول"
                  : scl == "he" ? "אודות"
                  : scl == "it" ? "A proposito"
                    : scl == "fr" ? "À propos"
                    : scl == "cass" ? "Acerca de"
                    : scl == "de" ? "Über uns"
                    : scl == "my" ? "Tentang"
                    : scl == "mx" ? "Acerca de"
                    : scl == "zh" ? "关于"
                    : scl == "th" ? "เกี่ยวกับ"
                    : scl == "id" ? "Tentang"
                    : scl == "vi" ? "Giới thiệu"
                    : scl == "zh-sg" ? "關於"
                    : scl == "zh-hk" ? "關於"
                                : 
                                "About"}
                                </Link>
                </NavItem>
                <NavItem>
                  <Link to="/contact" className="footer-link-rt">
                  {scl == "es" ? "Contáctanos" 
                  : scl == "ar" ? "اتصل بنا"
                  : scl == "he" ? "צרו קשר"
                  : scl == "it" ? "Contattaci"
                    : scl == "fr" ? "Contactez-nous"
                    : scl == "cass" ? "Contáctanos"
                    : scl == "de" ? "Kontakt"
                    : scl == "my" ? "Hubungi kami"
                    : scl == "mx" ? "Contáctanos"
                    : scl == "zh" ? "联系我们"
                    : scl == "th" ? "ติดต่อเรา"
                    : scl == "id" ? "Hubungi kami"
                    : scl == "vi" ? "Liên hệ"
                    : scl == "zh-sg" ? "聯繫我們"
                    : scl == "zh-hk" ? "聯繫我們"
                                : 
                                "Contact Us"}
                                </Link>
                </NavItem>
                <NavItem>
                  <Link to="/privacy" className="footer-link-rt">
                  {scl == "es" ? "Política de privacidad" 
                  : scl == "ar" ? "سياسة الخصوصية"
                  : scl == "he" ? "מדיניות פרטיות"
                  : scl == "it" ? "Politica di privacy"
                    : scl == "fr" ? "Politique de confidentialité"
                    : scl == "cass" ? "Política de privacidad"
                    : scl == "de" ? "Datenschutzerklärung"
                    : scl == "my" ? "Dasar privasi"
                    : scl == "mx" ? "Política de privacidad"
                    : scl == "zh" ? "隐私政策"
                    : scl == "th" ? "นโยบายความเป็นส่วนตัว"
                    : scl == "id" ? "Kebijakan privasi"
                    : scl == "vi" ? "Chính sách bảo mật"
                    : scl == "zh-sg" ? "隱私政策"
                    : scl == "zh-hk" ? "隱私政策"
                                : 
                                "Privacy policy"}
                                </Link>
                </NavItem>
                <NavItem>
                  <Link to="/terms" className="footer-link-rt">
                  {scl == "es" ? "Términos y condiciones" 
                  : scl == "ar" ? "الشروط والأحكام"
                  : scl == "he" ? "תנאי שימוש"
                  : scl == "it" ? "Condizioni generali"
                    : scl == "fr" ? "Conditions générales"
                    : scl == "cass" ? "Términos y condiciones"
                    : scl == "de" ? "Allgemeine Geschäftsbedingungen"
                    : scl == "my" ? "Terma dan syarat"
                    : scl == "mx" ? "Términos y condiciones"
                    : scl == "zh" ? "条款和条件"
                    : scl == "th" ? "ข้อกำหนดและเงื่อนไข"
                    : scl == "id" ? "Ketentuan dan ketentuan"
                    : scl == "vi" ? "Điều khoản và điều kiện"
                    : scl == "zh-sg" ? "條款和條件"
                    : scl == "zh-hk" ? "條款和條件"
                                : 
                                "Terms and conditions"}
                                </Link>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
