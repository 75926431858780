import React, { useState, useEffect, useRef } from 'react';

const AutoCompleteCustom = props => {


    const { items, value, setValue, setSearchKey, setCity, setCode, setCtid, setCountry, setAutocomplete, setGeolocation, setSiteContentLanguage } = props;
    const [ showPanel, setShowPanel ] = useState(false);
    const inputRef = useRef(null);



    const handleSelect = item => {
        setValue(item.label);
        if (setCity) setCity(item.city);
        if (setCode) setCode(item.code);
        if (setCountry) setCountry(item.country);
        if (item.ctid) setCtid(item.ctid);
        setShowPanel(false);
    }

    useEffect(() => {
        window.document.addEventListener('click', e => {
            if (e.target !== inputRef.current)
                setShowPanel(false);
        });
    }, []);

    return (
        <div className='auto-complete-rt'>
            
            {setSiteContentLanguage == "es" ? 
                <>
                <input
                type="text"
                name="pickup"
                autoComplete="new-password"
                placeholder="Ingresa una ciudad o aeropuerto"
                className='form-control'
                onChange={e => {setValue(e.target.value); setSearchKey && setSearchKey(e.target.value)}}
                onClick={e => {e.preventDefault(); setShowPanel(true)}}
                value={value}
                ref={inputRef}
            />
                </>
                : setSiteContentLanguage == "ar" ? 
                    <>
                    <input
                    type="text"
                    name="pickup"
                    autoComplete="new-password"
                    placeholder="أدخل مدينة أو مطار"
                    className='form-control'
                    onChange={e => {setValue(e.target.value); setSearchKey && setSearchKey(e.target.value)}}
                    onClick={e => {e.preventDefault(); setShowPanel(true)}}
                    value={value}
                    ref={inputRef}
                />
                    </>
                : setSiteContentLanguage == "he" ? 
                    <>
                    <input
                    type="text"
                    name="pickup"
                    autoComplete="new-password"
                    placeholder="הכנס עיר או נמל תעופה"
                    className='form-control'
                    onChange={e => {setValue(e.target.value); setSearchKey && setSearchKey(e.target.value)}}
                    onClick={e => {e.preventDefault(); setShowPanel(true)}}
                    value={value}
                    ref={inputRef}
                />
                    </>
                    : setSiteContentLanguage == "fr" ? 
                            <>
                            <input
                            type="text"
                            name="pickup"
                            autoComplete="new-password"
                            placeholder="Entrez une ville ou un aéroport"
                            className='form-control'
                            onChange={e => {setValue(e.target.value); setSearchKey && setSearchKey(e.target.value)}}
                            onClick={e => {e.preventDefault(); setShowPanel(true)}}
                            value={value}
                            ref={inputRef}
                        />
                            </>
                    : setSiteContentLanguage == "it" ? 
                            <>
                            <input
                            type="text"
                            name="pickup"
                            autoComplete="new-password"
                            placeholder="Inserisci una città o un aeroporto"
                            className='form-control'
                            onChange={e => {setValue(e.target.value); setSearchKey && setSearchKey(e.target.value)}}
                            onClick={e => {e.preventDefault(); setShowPanel(true)}}
                            value={value}
                            ref={inputRef}
                        />
                            </>
                            : setSiteContentLanguage == "cass" ? 
                            <>
                            <input
                            type="text"
                            name="pickup"
                            autoComplete="new-password"
                            placeholder="Introduzca una ciudad o aeropuerto"
                            className='form-control'
                            onChange={e => {setValue(e.target.value); setSearchKey && setSearchKey(e.target.value)}}
                            onClick={e => {e.preventDefault(); setShowPanel(true)}}
                            value={value}
                            ref={inputRef}
                        />
                            </>
                        : setSiteContentLanguage == "de" ? 
                        <>
                        <input
                        type="text"
                        name="pickup"
                        autoComplete="new-password"
                        placeholder="Geben Sie eine Stadt oder einen Flughafen ein"
                        className='form-control'
                        onChange={e => {setValue(e.target.value); setSearchKey && setSearchKey(e.target.value)}}
                        onClick={e => {e.preventDefault(); setShowPanel(true)}}
                        value={value}
                        ref={inputRef}
                    />
                        </>
                        : setSiteContentLanguage == "my" ? 
                        <>
                        <input
                        type="text"
                        name="pickup"
                        autoComplete="new-password"
                        placeholder="Masukkan bandar atau lapangan terbang"
                        className='form-control'
                        onChange={e => {setValue(e.target.value); setSearchKey && setSearchKey(e.target.value)}}
                        onClick={e => {e.preventDefault(); setShowPanel(true)}}
                        value={value}
                        ref={inputRef}
                    />
                        </>
                        : setSiteContentLanguage == "mx" ? 
                        <>
                        <input
                        type="text"
                        name="pickup"
                        autoComplete="new-password"
                        placeholder="Ingresa una ciudad o aeropuerto"
                        className='form-control'
                        onChange={e => {setValue(e.target.value); setSearchKey && setSearchKey(e.target.value)}}
                        onClick={e => {e.preventDefault(); setShowPanel(true)}}
                        value={value}
                        ref={inputRef}
                    />
                        </>
                        : setSiteContentLanguage == "zh" ? 
                        <>
                        <input
                        type="text"
                        name="pickup"
                        autoComplete="new-password"
                        placeholder="输入城市或机场"
                        className='form-control'
                        onChange={e => {setValue(e.target.value); setSearchKey && setSearchKey(e.target.value)}}
                        onClick={e => {e.preventDefault(); setShowPanel(true)}}
                        value={value}
                        ref={inputRef}
                    />
                        </>
                        : setSiteContentLanguage == "th" ? 
                        <>
                        <input
                        type="text"
                        name="pickup"
                        autoComplete="new-password"
                        placeholder="เลือกเมืองหรือสนามบิน"
                        className='form-control'
                        onChange={e => {setValue(e.target.value); setSearchKey && setSearchKey(e.target.value)}}
                        onClick={e => {e.preventDefault(); setShowPanel(true)}}
                        value={value}
                        ref={inputRef}
                    />
                        </>
                        : setSiteContentLanguage == "id" ? 
                        <>
                        <input
                        type="text"
                        name="pickup"
                        autoComplete="new-password"
                        placeholder="Masukkan kota atau bandara"
                        className='form-control'
                        onChange={e => {setValue(e.target.value); setSearchKey && setSearchKey(e.target.value)}}
                        onClick={e => {e.preventDefault(); setShowPanel(true)}}
                        value={value}
                        ref={inputRef}
                    />
                        </>
                          : setSiteContentLanguage == "vi" ? 
                          <>
                          <input
                          type="text"
                          name="pickup"
                          autoComplete="new-password"
                          placeholder="Nhập thành phố hoặc sân bay"
                          className='form-control'
                          onChange={e => {setValue(e.target.value); setSearchKey && setSearchKey(e.target.value)}}
                          onClick={e => {e.preventDefault(); setShowPanel(true)}}
                          value={value}
                          ref={inputRef}
                      />
                          </>
                          : setSiteContentLanguage == "zh-sg" ? 
                          <>
                          <input
                          type="text"
                          name="pickup"
                          autoComplete="new-password"
                          placeholder="輸入城市或機場"
                          className='form-control'
                          onChange={e => {setValue(e.target.value); setSearchKey && setSearchKey(e.target.value)}}
                          onClick={e => {e.preventDefault(); setShowPanel(true)}}
                          value={value}
                          ref={inputRef}
                      />
                          </>
                          : setSiteContentLanguage == "zh-hk" ? 
                          <>
                          <input
                          type="text"
                          name="pickup"
                          autoComplete="new-password"
                          placeholder="輸入城市或機場"
                          className='form-control'
                          onChange={e => {setValue(e.target.value); setSearchKey && setSearchKey(e.target.value)}}
                          onClick={e => {e.preventDefault(); setShowPanel(true)}}
                          value={value}
                          ref={inputRef}
                      />
                          </>

            : 
            <>
            <input
                type="text"
                name="pickup"
                autoComplete="new-password"
                placeholder="Enter a city or airport"
                className='form-control'
                onChange={e => {setValue(e.target.value); setSearchKey && setSearchKey(e.target.value)}}
                onClick={e => {e.preventDefault(); setShowPanel(true)}}
                value={value}
                ref={inputRef}
            />
            </>
            }
            {/* <input
                type="text"
                name="pickup"
                autoComplete="new-password"
                placeholder="Enter a city or airport"
                className='form-control'
                onChange={e => {setValue(e.target.value); setSearchKey && setSearchKey(e.target.value)}}
                onClick={e => {e.preventDefault(); setShowPanel(true)}}
                value={value}
                ref={inputRef}
            /> */}
            { items && items.length && showPanel ?
                <div>
                    <ul>
                        { items.map((item, index) =>
                            <li key={index} onClick={() => {handleSelect(item)}}>
                                { item.icon &&
                                    <img src={item.icon} alt="ico" height="20px" className='mr-2' />
                                }
                                <span>{item.label}</span>
                            </li>
                        )}

                    </ul>
                </div> :
                <></>
            }
        </div>
    )
}

export default AutoCompleteCustom;
