import React from 'react';
import {
    Container,
    Row,
    Col,
} from "reactstrap";
import EnterpriseImg from '../../assets/img/partners/enterprise.webp';
import HertzImg from '../../assets/img/partners/hertz.webp';
import AvisImg from '../../assets/img/partners/avis.webp';
import NationalImg from '../../assets/img/partners/national.webp';
import AlamoImg from '../../assets/img/partners/alamo.webp';
import BudgetImg from '../../assets/img/partners/budget.webp';
import EuropcarImg from '../../assets/img/partners/europcar.webp';
import SixtImg from '../../assets/img/partners/sixt.webp';
import DollarImg from '../../assets/img/partners/dollar.webp';
import ThriftyImg from '../../assets/img/partners/thrifty.webp';
import { useLocation } from "react-router-dom";


const Partners = () => {

    const search = useLocation().search;
    var scl = new URLSearchParams(search).get('scl');


    var ug = new URLSearchParams(search).get('ug');
    var ul = new URLSearchParams(search).get('ul');
    var ft = new URLSearchParams(search).get('ft');



    var scl = "";
    if(ug=="us" && ul=="en") {
        scl = "en";
    }else if (ug=="us" && ul=="es") {
        scl = "es";
    } 
    else if (ug=="ca" && ul=="en") {
        scl = "en";
    } else if (ug=="ca" && ul=="fr") {
        scl = "fr";
    } else if (ug=="ch" && ul=="it") {
        scl = "it";
    }
    else if (ug=="it" && ul=="it") {
        scl = "it";
    }
    else if (ug=="ch" && ul=="de") {
        scl = "de";
    }
    else if (ug=="ch" && ul=="fr") {
        scl = "fr";
    }
    else if (ug=="us" && ul=="cass") {
        scl = "cass";
    }

    else if (ug=="my" && ul=="ms") {
        scl = "my";
      }
      else if (ug=="my" && ul=="en") {
        scl = "en";
      }
      else if (ug=="de" && ul=="de") {
        scl = "de";
      }
      else if (ug=="de" && ul=="en") {
        scl = "en";
      }
      else if (ug=="sa" && ul=="ar") {
        scl = "ar";
      }

      else if (ug=="mx" && ul=="mx") {
        scl = "mx";
      }
      else if (ug=="th" && ul=="th") {
        scl = "th";
      }
      else if (ug=="id" && ul=="id") {
        scl = "id";
      }
      else if (ug=="hk" && ul=="zh") {
        scl = "zh";
      }
      else if (ug=="vn" && ul=="vi") {
        scl = "vi";
      }
      
    else if (ug=="il" && ul=="he") {
        scl = "he";
      }
      else if (ug=="sg" && ul=="zh") {
        scl = "zh";
      }
    else if (ug=="sg" && ul=="zh-sg") {
        scl = "zh-sg";
      }

    else if (ug=="hk" && ul=="zh-hk") {
        scl = "zh-hk";
      }

    else {
        scl = "en";
    }
    
    return (
        <>
            <Container id="partner-id">
                <Row className="text-center justify-content-center">
                    <Col lg="10">
                        <h2 className="display-3">
                        {scl == "es" ? "Busqueda de auto" 
                        : scl == "ar" ? "شركاؤنا"
                        : scl == "he" ? "השותפים שלנו"
                        : scl == "fr" ? "Nos partenaires"
                        : scl == "it" ? "I nostri partner"
                        : scl == "cass" ? "Nuestros Socios"
                        : scl == "de" ? "Unsere Partner"
                        : scl == "my" ? "Rakan kongsi kami"
                        : scl == "mx" ? "Nuestros Socios"
                        : scl == "zh" ? "我们的合作伙伴"
                        : scl == "th" ? "พันธมิตรของเรา"
                        : scl == "id" ? "Mitra kami"
                        : scl == "vi" ? "Các đối tác của chúng tôi"
                        : scl == "zh-sg" ? "我們的合作夥伴"
                        : scl == "zh-hk" ? "我們的合作夥伴"
    
                                : 
                                "Meet Our Partners"}
                                </h2>
                    </Col>
                </Row>
                <Row className="row-grid justify-content-center">
                    <div className="text-center partnerimg">
                        <img src={EnterpriseImg} alt="" className="partner_logo img img-fluid wow fadeInUp animated" data-wow-delay="0.5s" data-wow-offset="200" width="150" height="100" />
                    </div>
                    <div className="text-center partnerimg">
                        <img src={HertzImg} alt="" className="partner_logo img img-fluid wow fadeInUp animated" data-wow-delay="0.5s" data-wow-offset="200" width="150" height="106" />
                    </div>
                    <div className="text-center partnerimg">
                        <img src={AvisImg} alt="" className="partner_logo img img-fluid wow fadeInUp animated" data-wow-delay="0.5s" data-wow-offset="200" width="150" height="90" />
                    </div>
                    <div className="text-center partnerimg">
                        <img src={NationalImg} alt="" className="partner_logo img img-fluid wow fadeInUp animated" data-wow-delay="0.5s" data-wow-offset="200" width="150" height="111" />
                    </div>
                    <div className="text-center partnerimg">
                        <img src={AlamoImg} alt="" className="partner_logo img img-fluid wow fadeInUp animated" data-wow-delay="0.5s" data-wow-offset="200" width="150" height="92" />
                    </div>
                    <div className="text-center partnerimg">
                        <img src={BudgetImg} alt="" className="partner_logo img img-fluid wow fadeInUp animated" data-wow-delay="0.5s" data-wow-offset="200" width="150" height="100" />
                    </div>
                    <div className="text-center partnerimg">
                        <img src={EuropcarImg} alt="" className="partner_logo img img-fluid wow fadeInUp animated" data-wow-delay="0.5s" data-wow-offset="200" width="150" height="86" />
                    </div>
                    <div className="text-center partnerimg">
                        <img src={SixtImg} alt="" className="partner_logo img img-fluid wow fadeInUp animated" data-wow-delay="0.5s" data-wow-offset="200" width="150" height="100" />
                    </div>
                    <div className="text-center partnerimg">
                        <img src={DollarImg} alt="" className="partner_logo img img-fluid wow fadeInUp animated" data-wow-delay="0.5s" data-wow-offset="200" width="150" height="90" />
                    </div>
                    <div className="text-center partnerimg">
                        <img src={ThriftyImg} alt="" className="partner_logo img img-fluid wow fadeInUp animated" data-wow-delay="0.5s" data-wow-offset="200" width="150" height="86" />
                    </div>
                </Row>
                <Row>
                    <Col>
                        <p className="lead text-center">
                        {scl == "es" ? "Para contribuir a un cambio positivo y alcanzar nuestros objetivos de sostenibilidad, nos asociamos con muchas organizaciones extraordinarias en todo el mundo. Su experiencia nos permite hacer mucho más de lo que podríamos hacer solos, y su pasión y talento nos inspiran. Es un placer presentarte algunas de las organizaciones cuyos logros y compromisos son representativos de todas las organizaciones con las que tenemos la suerte de colaborar." 
                                : scl == "ar" ? "للمساهمة في التغيير الإيجابي وتحقيق أهدافنا في الاستدامة ، نتعاون مع العديد من المنظمات الاستثنائية في جميع أنحاء العالم. تسمح لنا خبرتهم بالقيام بأكثر مما يمكننا القيام به بمفردنا ، وشغفهم و talentتهم تلهمنا. يسعدنا أن نقدم لكم عددًا قليلاً من المنظمات التي تعكس إنجازات والتزامات جميع المنظمات التي نطلق عليها اسم شركائنا."
                                : scl == "he" ? "כדי לתרום לשינוי חיובי ולהשיג את יעדי הקיימות שלנו, אנו שותפים עם ארגונים יוצאי דופן רבים ברחבי העולם. המומחיות שלהם מאפשרת לנו לעשות הרבה יותר ממה שיכולנו לבד, והתשוקה והכשרון שלהם מעוררים השראה בקרבנו. זו זכות גדולה עבורנו להציג בפניכם מספר ארגונים שהישגיהם ומחויבותם מייצגים את כל הארגונים שאנו מאושרים לקרוא להם שותפים."
                                : scl == "fr" ? "Pour contribuer au changement positif et atteindre nos objectifs de durabilité, nous collaborons avec de nombreuses organisations extraordinaires du monde entier. Leur expertise nous permet de faire bien plus que ce que nous pourrions seuls, et leur passion et leur talent nous inspirent. Nous sommes ravis de vous présenter quelques-unes des organisations dont les réalisations et les engagements sont représentatifs de toutes les organisations que nous avons la chance d'appeler nos partenaires."
                                : scl == "it" ? "Per contribuire al cambiamento positivo e raggiungere i nostri obiettivi di sostenibilità, collaboriamo con molte organizzazioni straordinarie in tutto il mondo. La loro esperienza ci permette di fare molto di più di quanto potremmo da soli, e la loro passione e talento ci ispirano. È un piacere per noi presentarvi alcune delle organizzazioni i cui risultati e impegni sono rappresentativi di tutte le organizzazioni che abbiamo la fortuna di chiamare nostri partner."
                                : scl == "cass" ? "Para contribuir al cambio positivo y alcanzar nuestros objetivos de sostenibilidad, colaboramos con muchas organizaciones extraordinarias de todo el mundo. Su experiencia nos permite hacer mucho más de lo que podríamos solos, y su pasión y talento nos inspiran. Es un placer para nosotros presentarles algunas de las organizaciones cuyos logros y compromisos son representativos de todas las organizaciones a las que tenemos la suerte de llamar socios."
                                : scl == "de" ? "Um positive Veränderungen zu bewirken und unsere Nachhaltigkeitsziele zu erreichen, arbeiten wir mit vielen außergewöhnlichen Organisationen auf der ganzen Welt zusammen. Ihre Expertise ermöglicht uns, viel mehr zu tun, als wir alleine könnten, und ihre Leidenschaft und ihr Talent inspirieren uns. Wir freuen uns, Ihnen einige der Organisationen vorzustellen, deren Leistungen und Verpflichtungen repräsentativ sind für alle Organisationen, die wir glücklicherweise unsere Partner nennen dürfen."
                                : scl == "my" ? "Untuk menyumbang kepada perubahan positif dan mencapai matlamat kelestarian kami, kami bekerjasama dengan banyak organisasi luar biasa di seluruh dunia. Kepakaran mereka membolehkan kami melakukan lebih banyak daripada yang kami boleh lakukan bersendirian, dan keghairahan dan bakat mereka memberi inspirasi kepada kami. Adalah satu kegembiraan bagi kami untuk memperkenalkan anda kepada beberapa organisasi yang pencapaian dan komitmennya mewakili semua organisasi yang kami beruntung dapat panggil rakan kongsi kami."
                                : scl == "mx" ? "Para contribuir al cambio positivo y alcanzar nuestros objetivos de sustentabilidad, colaboramos con muchas organizaciones extraordinarias de todo el mundo. Su experiencia nos permite hacer mucho más de lo que podríamos solos, y su pasión y talento nos inspiran. Es un placer para nosotros presentarles algunas de las organizaciones cuyos logros y compromisos son representativos de todas las organizaciones a las que tenemos la suerte de llamar socios."
                                : scl == "zh" ? "为了促成积极的改变并实现我们的可持续发展目标，我们与世界各地的许多非凡组织合作。 他们的专业知识使我们能够做得比我们独自做得多，而他们的热情和才华激励了我们。 很高兴向您介绍一些成就和承诺代表我们幸运地称为合作伙伴的所有组织的组织。"
                                : scl == "th" ? "เพื่อมีส่วนร่วมในการเปลี่ยนแปลงเชิงบวกและบรรลุเป้าหมายด้านความยั่งยืนของเรา เราร่วมมือกับองค์กรพิเศษมากมายทั่วโลก ความรู้ความเชี่ยวชาญของพวกเขาช่วยให้เราทำได้มากเกินกว่าที่เราจะทำคนเดียวได้ และความรักและความสามารถของพวกเขาทำให้เรามีแรงบันดาลใจ เป็นความยินดีของเราที่จะแนะนำคุณให้รู้จักกับองค์กรจำนวนหนึ่งที่ความสำเร็จและความมุ่งมั่นเป็นตัวแทนขององค์กรทั้งหมดที่เราโชคดีที่ได้เรียกว่าพันธมิตรของเรา"
                                : scl == "id" ? "Untuk berkontribusi pada perubahan positif dan mencapai tujuan keberlanjutan kami, kami bermitra dengan banyak organisasi luar biasa di seluruh dunia. Keahlian mereka memungkinkan kami melakukan lebih banyak daripada yang bisa kami lakukan sendiri, dan semangat dan talenta mereka menginspirasi kami. Merupakan suatu kegembiraan bagi kami untuk memperkenalkan Anda kepada beberapa organisasi yang pencapaian dan komitmennya mewakili semua organisasi yang kami beruntung dapat menyebutnya sebagai mitra kami."
                                : scl == "vi" ? "Để đóng góp cho sự thay đổi tích cực và đạt được các mục tiêu bền vững của chúng tôi, chúng tôi hợp tác với nhiều tổ chức phi thường trên toàn thế giới. Năng lực chuyên môn của họ cho phép chúng tôi làm được nhiều hơn những gì chúng tôi có thể tự mình làm, và niềm đam mê và tài năng của họ truyền cảm hứng cho chúng tôi. Rất vinh dự được giới thiệu cho bạn một số tổ chức có thành tích và cam kết đại diện cho tất cả các tổ chức mà chúng tôi may mắn được gọi là đối tác."
                                : scl == "zh-sg" ? "為了促成積極的改變並實現我們的可持續發展目標，我們與世界各地的許多非凡組織合作。 他們的專業知識使我們能夠做得比我們獨自做得多，而他們的熱情和才華激勵了我們。 很高興向您介紹一些成就和承諾代表我們幸運地稱為合作夥伴的所有組織的組織。"
                                : scl == "zh-hk" ? "為了促成積極的改變並實現我們的可持續發展目標，我們與世界各地的許多非凡組織合作。 他們的專業知識使我們能夠做得比我們獨自做得多，而他們的熱情和才華激勵了我們。 很高興向您介紹一些成就和承諾代表我們幸運地稱為合作夥伴的所有組織的組織。"
                                : 
                                "To contribute to positive change and achieve our sustainability goals, we partner with many extraordinary organizations around the world. Their expertise enables us to do far more than we could alone, and their passion and talent inspire us. It is our pleasure to introduce you to a handful of the organizations whose accomplishments and commitments are representative of all the organizations we are fortunate to call our partners."}

                            
                        </p>
                    </Col>
                </Row>
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 2560 100"
                    x="0"
                    y="0"
                >
                    <polygon className="fill-white" points="2560 0 2560 100 0 100" />
                </svg>
            </div>
        </>
    )
}

export default Partners;