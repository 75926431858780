import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Terms = () => {
    return (
        <>
            <section className="mt-6-rt pt-4">
                <Container className="terms-container-rt">
                    <Row>
                        <Col>
                            <h1>Terms of Use</h1>
                            <h2 className='font-bold'>Terms of Use and Services</h2>
                            <h3>Date of last effective update is 19, January 2019.</h3>
                            <p>
                                Welcome to the website located at <a href="http://www.save70.com/">https://save268.com/</a> (hereinafter, the “Site”), provided to you by The Videos Nation (hereinafter, "Save268").  If you continue to browse and use this website you are hereby acknowledging that you have read, understood and agreed to be bound by and under these Terms of Use and Services (the “Terms”) – whether or not you have registered into the Site – and which together with our <a href="https://save268.com/privacypolicy">Privacy Policy</a> (the “Policy”), shall govern Save268’s relationship with you in relation to the Site and any products and services offered thereby (collectively, the “Services”). These Terms constitute the entire agreement between you and us.  If you do not agree to these Terms, please exit the Site or stop use of our services.
                            </p>
                            <p>
                                The terms or ‘us’, ‘ours’ or ‘we’ as used herein refer to Save268 and/or its parent, subsidiaries, assignees, successors, affiliates, and/or brands. The terms ‘you’, ‘your’, and/or ‘yourself’ as used herein refer to you, the user and/or browser of our Site.  To prevent any breach of the Terms hereof and to enforce any remedy, we shall take any technical, legal or managerial measures that we deem, in our sole discretion, necessary and appropriate, without prior notice to you or any other user.  Henceforth, the use of this Site is subject to the following Terms:
                            </p>
                            
                            <h3>Minors</h3>
                            <p>
                                Save268 undertakes the protection of the rights of minors and underage persons, and thereby encourages parents, legal guardians and responsible adults to be actively involved in the safe use of the Internet by their minors.  Under the terms of the Children’s Online Privacy Protection Act, through the use and/or simple access to the Site, you hereby represent and warrant that you are at least thirteen (13) years of age, or older, as of the date of first access to our Site and, if you are still a minor (which will vary upon the jurisdiction where you reside), are therefore using the Site under the direct supervision of your parent, legal guardian or responsible adult.
                            </p>
    
                            <h3>Updates</h3>
                            <p>
                                Save268 hereby reserves the right to update, modify, discontinue, or terminate the Site, the Terms and/or the Policy, at any time and at our sole and final discretion.  Any changes to these Terms will be displayed in the Site, and we may notify you through the Site, by email and/or short message service.  Please, refer to the date first shown above for the date where effective changes were last undertook.  Your continued use of the Site after the last effective date of amendment to these Terms – either by registration or simple use – indicates your acceptance of any modifications thereto.
                            </p>
                            <p>
                                Your use of any information and/or materials on this Site is entirely at your own risk, for which we shall not be held liable.  It shall be your own responsibility to ensure that any products, services, or information available through this Site meet your specific travel, hotel and/or transportation requirements.
                            </p>

                            <h3>Spam</h3>
                            <p>We take spam seriously, and we encourage you and our users to report any spam activities to us. We will not tolerate, nor allow others to undertake, any and all use of your account and/or any information regarding the Site, pursuant to the remission of any other unsolicited bulk communication to any of our users or to any third party.  Under the CAN-SPAM Act, you may not access the Site to harvest and/or collect any information about our users, for any purpose, and any commercial communication that you may receive from us and/or our partners, licensors, suppliers, and affiliates will clearly indicate measures to stop receiving such communications, including unsubscribe links and appertaining instructions.</p>

                            <h3>Services</h3>
                            <p>
                                Save268 is a fully fledged online marketplace connecting consumers with travel providers/websites throughout the world.  You may receive information from our Site either by filling in some details or automatically, through our platform’s functionalities.  With our Site’s functionalities, it will be easy for you to choose the travel service that best fits your necessities (e.g., date, destination, hotel, amenities, category, number of travellers, price range), while our database and search engine’s functionalities will provide you with multiple results and quotes from leading travel websites and tour providers.  We may also suggest general/random products and services to you while browsing the Site, along with general travel information based upon blog articles and other contact pages available throughout the Site.
                            </p>
                            <p>
                                Please be advised, however, that: (i) we are neither an online travel agency, nor a travel services provider; (ii) that we do not provide quotes directly to you and any other consumer; and (iii) that none of the actual final booking occurs on our Site or platform; thus, we shall not be held liable or responsible for any damages, losses, costs and/or liabilities arising out of or related to any and all cancellations, expired deals, changes, inaccuracies, in-congruencies, dates, quotes, prices, destinies, flight numbers, airlines, airline alliances, airports/gates, hotel amenities, locations, addresses, pictures, descriptions, reviews, coordinates and/or any travel information shown throughout the Site.
                            </p>
                            <p>
                                All information posted throughout our Site is intended for reference and convenience purposes only, which may include inaccuracies or errors, and does not constitute a direct endorsement of any kind of product or service and any and all rates shown to you may change subject to your specific dates, season, deductibles, type of service and location, among other factors, at any time and without prior notice.  Therefore, we will neither be held liable nor responsible for any damages, losses, or travel expenses you or your guests may experience as a result thereof.
                            </p>
                            <p>
                                To use our Services, you may be able to create a traveller profile and register into our Site.  Regardless of whether you create a personalized profile or not, we may collect certain personal data and other information from you, and we hereby invite you to revise our Policy to be aware of your rights and obligations regarding such data use.  You hereby warrant to us that all information supplied by you on this Site is true, accurate, current, and complete.
                            </p>
    
                            <h3>License</h3>
                            <p>
                                When entering the Site or using our Services, you and other users may post comments, questions, ideas, upload photos and other kind of multimedia content. Save268 hereby reserves the right (but not the obligation) to remove and/or edit such content, and we have the right but not the obligation to monitor and edit or remove any activity or content; thereby enacting the necessary measures to moderate any comments and to control user behaviour within our Site.
                            </p>
                            <p>
                                Henceforth, through the simple use of our Site and any Services therein, you hereby grant Save268 an unlimited, non-exclusive, assignable, royalty-free, perpetual, irrevocable, for all the world, sub-licensable right and license to use, reproduce, adapt, create derivative works from, distribute and display any content you may upload or transfer throughout the Site, when posting messages, uploading files, inputting data or otherwise submitting any kind of content to us, and you hereby represent and warrant to Save268 that you have all the rights, licenses, authorizations and authority necessary to grant the abovementioned license.
                            </p>
                            <p>
                                Save268 hereby grants you a personal, limited, revocable, worldwide, royalty-free, non-assignable, non-commercial, non-sublicensable and non-exclusive authorization to use the services and/or software provided to you by us through the Site; for the sole purpose of enabling, you to use and receive the Services of the Site, only as provided for in these Terms, and subject to your compliance thereof.
                            </p>

                            <h3>Takedown Notices, Infringement</h3>
                            <p>
                                In compliance with the Digital Millennium Copyright Act, Save268 takes copyright infringement seriously, and is ready to remove any content posted upon duly notice and request by a copyright holder.  If you believe that any content or other material provided through the Site allegedly infringes the copyright of you or of a third party, please notify us of your claim to:  <a href="http://www.save70.com/contact-us">https://save268.com/contact-us</a> (subject: “Takedown Request”).  Save268 may remove any content if it believes or has reason to believe such content infringes on another’s copyright, without prior notice and at any time and at its sole discretion.  We may also disable your or any user ability to use the Site or terminate your access thereof.
                            </p>
                            <p>
                                The notification must be in writing and must contain the following information, at a minimum: (i) a signature and identification of the title holder and/or the person authorized to act; (ii) a description of the copyrighted work that allegedly has been infringed; (iii) contact information, such as your address and/or your email address (for us to deliver our response to you); and (iv) a statement indicating that the information provided in the notice is true and accurate.
                            </p>

                            <h3>Ownership</h3>
                            <p>
                                All trademarks reproduced in our Site are the property of – or licensed to – the respective title holder acknowledged (or not) on the Site, who may or may not be affiliated with, connected to, or sponsored by us and/or affiliates, partners, advertisers, and subsidiaries.  Reproduction of the content within the Site without our written authorization is thereto prohibited.  As between you and us, you own your content, and we own our content.  We also own, control and/or have sufficient right and authorization for the copyrights, trademarks, service marks, trade names, and other intellectual and proprietary rights throughout the world associated with the Site, which are protected by copyright, trade dress, patent, trademark laws and all other applicable intellectual and proprietary rights and laws.
                            </p>

                            <h3>Third party links</h3>
                            <p>
                                From time to time, our Site and Services may contain hyperlinks to other websites.  These links are provided for your convenience and to provide further travel information and does not mean that we endorse such websites or their products and services; and therefore, your use thereof is at your own risk.  We have no responsibility for the content of the linked websites, which are thereto governed under their respective privacy policies, terms and conditions and legal disclaimers.  Please review the aforementioned documents, which will govern any use thereof.  You may not create a link to this website from another website or document without Save268’s prior written consent.
                            </p>

                            <h3>Advertising, Opt-out</h3>
                            <p>
                                We may support the Site and Services through advertising and other commercial opportunities.  These advertisements may be tailored to the content of the Site, and the nature and extent of such advertising by us is subject to change without prior notice.  Please refer to our Policy for information regarding advertisement opt-out.
                            </p>
                            <p>
                                The Site may allow you to subscribe to a Newsletter service, which may be provided by us or through an authorized third party.  Through our Newsletter, you may receive quotes and deals information according to your user preferences.  In compliance with the CAN-SPAN Act, each and every one of our Newsletter’s communications will provide you the option to unsubscribe or change your preferences at any time.  Please refer to our Policy for further information.
                            </p>
                            <p>
                                This Site uses the ClickTale customer experience analytics service, which helps us analyse how visitors use our Site.  We do not collect through the ClickTale service or transfer to ClickTale any personal identifiable information.  ClickTale does not create a user profile for the purpose of tracking a user across unrelated web sites and will only use such information pursuant to its Privacy Policy (currently located at <a href="http://www.clicktale.com/privacy_service.aspx">http://www.clicktale.com/privacy_service.aspx</a>).  You can choose to disable the ClickTale service by following the instructions located at <a href="http://www.clicktale.net/disable.html">http://www.clicktale.net/disable.html</a>.  For more information regarding remarketing advertising, please refer to our Policy.
                            </p>

                            <h3>Generals</h3>
                            <p>
                                By accessing or using the Site, you hereby represent and warrant that you have read, understood, and agreed to be bound by and under these Terms.  The lowest (or any) advertised rates in our Site are not necessarily available from all providers appearing on our Site and any search results therefrom – which we cannot ultimately control – are out of our complete scope.  Some of the images shown in the Site are licensed and/or purchased stock photos, and are only shown for illustration purposes and, therefore, they may not accurately reflect the appearance or characteristics of a certain hotel, place, or travel destiny.  Some or all of the prices and quotes shown online through the Site may have expired since last update by our Site and/or the service travel providers.  Some or all of the articles, reviews and/or blog posts regarding travel destinations are undertook by external third parties, and do not reflect Save268, nor its partners’, officers’, agents’, directors’, licensees’, or shareholders’ opinions, nor does Save268 guarantees their actual veracity.
                            </p>
                            <p>
                                Our Site may receive your geo-location information, either if provided by you or through other mathematical methods using publicly available, not personally identifiable information.  Through the use of such geo-location information, the Site may provide you with personalized, tailored quotes and information according to your location (i.e., country, region, and city).
                            </p>
                            <p>
                                We do not directly offer travel services, nor do we charge for them throughout the Site, and therefore we encourage you to revise the information regarding travel conditions in various along with the level of risk associated with particular international destinations, which can currently be found at <a href="http://www.state.gov/">www.state.gov</a>, <a href="http://www.tsa.gov/">www.tsa.gov</a>, <a href="http://www.dot.gov/">www.dot.gov</a>, <a href="http://www.faa.gov/">www.faa.gov</a> and <a href="http://www.customs.gov/">www.customs.gov</a>.
                                Save268’s maximum cumulative liability to you for any losses or damages arising out of or in connection with your access of the Site and any Services therein shall be limited to: (i) the amount paid, if any, by you to us in connection with the Site and any Services therein during the 12 months prior to the action giving rise to such liability; or (ii) USD$50 (Fifty United States Dollars), whichever amount results inferior.
                            </p>
                            <p>
                                Your use of this Site and any claim, dispute and/or cause of action that might arise between you and us, without regard to conflict of law to principles, shall be subject to the laws of the State of Israel, without regard to the conflicts of laws principles thereof.  You hereby represent that you consent to the exclusive jurisdiction and venue of the competent Courts of the City of Tel-Aviv, Israel, for in all disputes arising out of or relating to the use of this Site.
                            </p>
                            <p>
                                Failure by us to impose any of its rights under these Terms shall not be interpreted as a waiver of any rights hereon, nor any other rights with respect to the matter hereof.
                            </p>
                            <p>
                                Any non-legal notices or communications hereunder, including those regarding modifications to these Terms, shall be in writing and delivered by in writing (to any address you may provide).  For notices made by e-mail, the date of receipt will be deemed the date in which such notice is transmitted.
                            </p>
                            <p>
                                You may not assign or transfer these Terms, by operation of law or otherwise, without our prior written consent.  Notwithstanding the foregoing, we may assign any rights or obligations hereunder.  Any rights not expressly granted herein are thereby reserved.  These terms will insure to the benefit of any successors of the parties.
                            </p>
                            <p>
                                You agree that no agency, joint venture, partnership, employment, or agency relationship exists between you and us.
                            </p>
                            <p>
                                Currency exchange rates displayed on the Site are based on various sources and should only be used as reference.  Actual exchange rates will vary depending on the location, bank, credit card and/or payment processing systems used by you.
                            </p>

                            <h3>Questions about us or these Terms</h3>
                            <p>
                                If you have any questions about our Terms, please contact us at: <a href="https://save268.com/contact-us/">https://save268.com/contact-us/</a>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Terms;