import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendSubscribe } from '../../actions';
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";

import { useLocation } from "react-router-dom";


const pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/; 

const Contact = () => {
    const dispatch = useDispatch();
    const [ email, setEmail ] = useState('');
    const [ validate, setValidate ] = useState(false);

    const handleSubscribe = e => {
        e.preventDefault();
        setValidate(true);

        if (validateForm()) {
            dispatch(sendSubscribe(email));
        }
    }

    const validateForm = () => {
        return email !== '' && pattern.test(email);
    }

    const search = useLocation().search;
    var scl = new URLSearchParams(search).get('scl');

    var ug = new URLSearchParams(search).get('ug');
    var ul = new URLSearchParams(search).get('ul');
    var ft = new URLSearchParams(search).get('ft');




    var scl = "";
    if(ug=="us" && ul=="en") {
        scl = "en";
    }else if (ug=="us" && ul=="es") {
        scl = "es";
    } 
    else if (ug=="ca" && ul=="en") {
        scl = "en";
    } else if (ug=="ca" && ul=="fr") {
        scl = "fr";
    } else if (ug=="ch" && ul=="it") {
        scl = "it";
    }
    else if (ug=="it" && ul=="it") {
        scl = "it";
    }
    else if (ug=="ch" && ul=="de") {
        scl = "de";
    }
    else if (ug=="ch" && ul=="fr") {
        scl = "fr";
    }
    else if (ug=="us" && ul=="cass") {
        scl = "cass";
    }

    else if (ug=="my" && ul=="ms") {
        scl = "my";
      }
      else if (ug=="my" && ul=="en") {
        scl = "en";
      }
      else if (ug=="de" && ul=="de") {
        scl = "de";
      }
      else if (ug=="de" && ul=="en") {
        scl = "en";
      }
      else if (ug=="sa" && ul=="ar") {
        scl = "ar";
      }
      
      else if (ug=="mx" && ul=="mx") {
        scl = "mx";
      }
      else if (ug=="th" && ul=="th") {
        scl = "th";
      }
      else if (ug=="id" && ul=="id") {
        scl = "id";
      }
      else if (ug=="hk" && ul=="zh") {
        scl = "zh";
      }
      else if (ug=="vn" && ul=="vi") {
        scl = "vi";
      }

      
    else if (ug=="il" && ul=="he") {
        scl = "he";
      }
    else if (ug=="sg" && ul=="zh") {
        scl = "zh";
      }
    else if (ug=="sg" && ul=="zh-sg") {
        scl = "zh-sg";
      }

    else if (ug=="hk" && ul=="zh-hk") {
        scl = "zh-hk";
      }
    else {
        scl = "en";
    }

    return (
        <Container id="contact-id">
            <Row className="justify-content-center py-5">
                <Col lg="8">
                    <Card className="bg-gradient-secondary shadow">
                        <CardBody className="p-lg-5">
                            <h4 className={scl == "ar" || scl == "he" ? "mb-1 text-right" : "mb-1"}>
                            {scl == "es" ? "Regístrate para recibir ofertas exclusivas" 
                            : scl == "ar" ? "اشترك في العروض الحصرية"
                            : scl == "he" ? "הירשם לעסקאות בלעדיות"
                            : scl == "fr" ? "S'INSCRIRE AUX OFFRES EXCLUSIVES"
                            : scl == "it" ? "ISCRIVITI ALLE OFFERTE ESCLUSIVE"
                            : scl == "cass" ? "SUSCRÍBETE A OFERTAS EXCLUSIVAS"
                            : scl == "de" ? "KOSTENLOSE ANMELDUNG FÜR EXKLUSIVE ANGEBOTE"
                            : scl == "my" ? "DAFTAR UNTUK Tawaran Eksklusif"
                            : scl == "mx" ? "SUSCRÍBETE A OFERTAS EXCLUSIVAS"
                            : scl == "zh" ? "注册独家优惠"
                            : scl == "th" ? "สมัครรับข้อเสนอพิเศษ"
                            : scl == "id" ? "Daftar untuk penawaran eksklusif"
                            : scl == "vi" ? "Đăng ký ưu đãi độc quyền"
                            : scl == "zh-sg" ? "加入独家优惠"
                            : scl == "zh-hk" ? "加入獨家優惠"
                                : 
                                "SIGN UP FOR EXCLUSIVE DEALS"}
                                </h4>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-email-83" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    {scl == "es" ? 
                                    <>
                                    <Input
                                        placeholder="Dirección de correo electrónico"
                                        type="email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                    </> 
                                    : scl == "ar" ? 
                                        <>
                                        <Input
                                            placeholder="عنوان البريد الإلكتروني"
                                            type="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                        </> 
                                    : scl == "he" ? 
                                        <>
                                        <Input
                                            placeholder="כתובת דוא"
                                            type="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                        </> 
                                        : scl == "fr" ? 
                                        <>
                                        <Input
                                            placeholder="Adresse e-mail"
                                            type="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                        </> 
                                        : scl == "it" ? 
                                        <>
                                        <Input
                                            placeholder="Indirizzo e-mail"
                                            type="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                        </> 
                                        : scl == "cass" ? 
                                        <>
                                        <Input
                                            placeholder="Dirección de correo electrónico"
                                            type="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                        </>
                                        : scl == "de" ? 
                                            <Input
                                                placeholder="E-Mail-Adresse"
                                                type="email"
                                                value={email}
                                                onChange={e => setEmail(e.target.value)}
                                            />
                                        : scl == "my" ? 
                                            <Input
                                            placeholder="Alamat e-mel"
                                            type="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            />
                                        : scl == "mx" ? 
                                            <Input
                                            placeholder="Dirección de correo electrónico"
                                            type="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            />
                                        : scl == "zh" ? 
                                            <Input
                                            placeholder="电子邮件地址"
                                            type="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            />
                                        : scl == "th" ? 
                                            <Input
                                            placeholder="ที่อยู่อีเมล"
                                            type="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            />
                                        : scl == "id" ? 
                                            <Input
                                            placeholder="Alamat email"
                                            type="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            />
                                        : scl == "vi" ?
                                            <Input
                                            placeholder="Địa chỉ email"
                                            type="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            />
                                        : scl == "zh-sg" ? 
                                            <Input
                                            placeholder="電子郵件地址"
                                            type="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            />
                                        : scl == "zh-hk" ? 
                                            <Input
                                            placeholder="電子郵件地址"
                                            type="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            />
                                : 
                                <>
                                <Input
                                        placeholder="Email address"
                                        type="email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </>}
                                    {/* <Input
                                        placeholder="Email address"
                                        type="email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    /> */}
                                </InputGroup>
                                { validate &&
                                    <>
                                        <label className={`mt-1 text-danger ${email === '' ? 'd-block' : 'd-none'}`}>This is required field.</label>
                                        <label className={`mt-1 text-danger ${email !== '' && !pattern.test(email) ? 'd-block' : 'd-none'}`}>Please input the valid email.</label>    
                                    </>
                                }
                            </FormGroup>
                            <div>
                                <Button
                                    block
                                    className="btn-round"
                                    color="default"
                                    size="lg"
                                    type="button"
                                    onClick={handleSubscribe}
                                >
                                    {scl == "es" ? "SUSCRIBIRSE" 
                                    : scl == "ar" ? "الاشتراك"
                                    : scl == "he" ? "הרשמה"
                                    : scl == "fr" ? "S'ABONNER"
                                    : scl == "it" ? "ISCRIVITI"
                                    : scl == "cass" ? "SUSCRIBIRSE"
                                    : scl == "de" ? "ANMELDEN"
                                    : scl == "my" ? "Langgan"
                                    : scl == "mx" ? "SUSCRIBIRSE"
                                    : scl == "zh" ? "订阅"
                                    : scl == "th" ? "สมัครสมาชิก"
                                    : scl == "id" ? "Berlangganan"
                                    : scl == "vi" ? "Đăng ký"
                                    : scl == "zh-sg" ? "訂閱"
                                    : scl == "zh-hk" ? "訂閱"
                                : 
                                "Subscribe"}
                                    
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Contact;