import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Headroom from "headroom.js";
import {
  UncontrolledCollapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

import { useLocation } from "react-router-dom";


const DemoNavbar = () => {
  const history = useHistory();

    const search = useLocation().search;
    var scl = new URLSearchParams(search).get('scl');

    var ug = new URLSearchParams(search).get('ug');
    var ul = new URLSearchParams(search).get('ul');
    var ft = new URLSearchParams(search).get('ft');



    var scl = "";
    if(ug=="us" && ul=="en") {
        scl = "en";
    }else if (ug=="us" && ul=="es") {
        scl = "es";
    } 
    else if (ug=="ca" && ul=="en") {
        scl = "en";
    } else if (ug=="ca" && ul=="fr") {
        scl = "fr";
    } else if (ug=="ch" && ul=="it") {
        scl = "it";
    }
    else if (ug=="it" && ul=="it") {
        scl = "it";
    }
    else if (ug=="ch" && ul=="de") {
        scl = "de";
    }
    else if (ug=="ch" && ul=="fr") {
        scl = "fr";
    }
    else if (ug=="us" && ul=="cass") {
        scl = "cass";
    }

    else if (ug=="my" && ul=="ms") {
      scl = "my";
    }
    else if (ug=="my" && ul=="en") {
      scl = "en";
    }
    else if (ug=="de" && ul=="de") {
      scl = "de";
    }
    else if (ug=="de" && ul=="en") {
      scl = "en";
    }
    else if (ug=="sa" && ul=="ar") {
      scl = "ar";
    }

    else if (ug=="mx" && ul=="mx") {
      scl = "mx";
    }
    else if (ug=="th" && ul=="th") {
      scl = "th";
    }
    else if (ug=="id" && ul=="id") {
      scl = "id";
    }
    else if (ug=="hk" && ul=="zh") {
      scl = "zh";
    }
    else if (ug=="vn" && ul=="vi") {
      scl = "vi";
    }

    else if (ug=="il" && ul=="he") {
      scl = "he";
    }
    else if (ug=="sg" && ul=="zh") {
      scl = "zh";
    }
  else if (ug=="sg" && ul=="zh-sg") {
      scl = "zh-sg";
    }

  else if (ug=="hk" && ul=="zh-hk") {
      scl = "zh-hk";
    }
    
    else {
        scl = "en";
    }
    // alert("----scl----", search);
  
  useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    headroom.init();
  }, []);

  const handleScroll = to => {
    switch (to) {
      case 'home': {
        document.getElementById("header-id").scrollIntoView({ behavior: "smooth" });
        break;
      }
      case 'partner': {
        document.getElementById("partner-id").scrollIntoView({ behavior: "smooth" });
        break;
      }
      case 'contact': {
        document.getElementById("contact-id").scrollIntoView({ behavior: "smooth" });
        break;
      }
      default: {
        break;
      }
    }
  }

  return (
    <>
      <header className="header-global headroom--not-top" id="header-id">
        <Navbar
          className="navbar-main navbar-transparent navbar-light headroom"
          expand="lg"
          id="navbar-main"
        >
          <Container>
            <Link className="mr-lg-5 logo-link-rt" to="/">
              <img
                alt="..."
                src={require("../../assets/img/logo.png")}
              />
            </Link>
            <button className="navbar-toggler" id="navbar_global">
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse
              toggler="#navbar_global"
              navbar
            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/">
                      <img
                        alt="..."
                        src={require("../../assets/img/logo.png")}
                      />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button className="navbar-toggler" id="navbar_global">
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="align-items-lg-center ml-lg-auto" navbar>
                <NavItem>
                  <NavLink
                    className="nav-link-icon cursor-pointer"
                    to="#"
                    onClick={() => {history.push('/'); handleScroll('home')}}
                  >
                    <span className="nav-link-inner--text ml-2">
                    {scl == "es" ? "Inicio" 
                    : scl == "ar" ? "الصفحة الرئيسية"
                    : scl == "he" ? "בית"
                    : scl == "it" ? "Home"
                    : scl == "fr" ? "Accueil"
                    : scl == "cass" ? "Inicio"
                    : scl == "de" ? "Startseite"
                    : scl == "my" ? "Laman Utama"
                    : scl == "mx" ? "Inicio"
                    : scl == "zh" ? "主页"
                    : scl == "th" ? "หน้าแรก"
                    : scl == "id" ? "Beranda"
                    : scl == "vi" ? "Trang chủ"
                    : scl == "zh-sg" ? "首頁"
                    : scl == "zh-hk" ? "首頁"
                                : 
                                "Home"}
                      
                    </span>
                  </NavLink>
                </NavItem>
                {
                  (history.location.pathname.includes('clickid') || history.location.pathname === '/' || history.location.pathname === '/new') &&
                  <>
                    <NavItem>
                      <NavLink
                        className="nav-link-icon cursor-pointer"
                        to="/"
                        onClick={() => handleScroll('partner')}
                      >
                        <span className="nav-link-inner--text ml-2">
                        {scl == "es" ? "Nuestros socios" 
                         : scl == "ar" ? "شركاؤنا"
                         : scl == "he" ? "השותפים שלנו"
                         : scl == "it" ? "I nostri partner"
                        : scl == "fr" ? "Nos partenaires"
                        : scl == "cass" ? "Nuestros Socios"
                        : scl == "de" ? "Unsere Partner"
                        : scl == "my" ? "Rakan kongsi kami"
                        : scl == "mx" ? "Nuestros Socios"
                        : scl == "zh" ? "我们的合作伙伴"
                        : scl == "th" ? "พันธมิตรของเรา"
                        : scl == "id" ? "Mitra kami"
                        : scl == "vi" ? "Các đối tác của chúng tôi"
                        : scl == "zh-sg" ? "我們的合作夥伴"
                        : scl == "zh-hk" ? "我們的合作夥伴"
                                : 
                                "Our Partners"}
                                
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className="nav-link-icon cursor-pointer"
                        to="/"
                        onClick={() => handleScroll('contact')}
                      >
                        <span className="nav-link-inner--text ml-2">
                        {scl == "es" ? "SUSCRIBIRSE" 
                        : scl == "ar" ? "الاشتراك"
                        : scl == "he" ? "הרשמה"
                        : scl == "it" ? "ISCRIVITI"
                        : scl == "fr" ? "S'ABONNER"
                        : scl == "cass" ? "SUSCRIBIRSE"
                        : scl == "de" ? "ANMELDEN"
                        : scl == "my" ? "Langgan"
                        : scl == "mx" ? "SUSCRIBIRSE"
                        : scl == "zh" ? "订阅"
                        : scl == "th" ? "สมัครสมาชิก"
                        : scl == "id" ? "Berlangganan"
                        : scl == "vi" ? "Đăng ký"
                        : scl == "zh-sg" ? "訂閱"
                        : scl == "zh-hk" ? "訂閱"
                                : 
                                "Subscribe"}
                                
                        </span>
                      </NavLink>
                    </NavItem>
                  </>
                }
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
}

export default DemoNavbar;
